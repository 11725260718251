import { createSlice } from '@reduxjs/toolkit';
import { TApiUser, TAuthUser, TCreateUser, TUser, TUserRole } from '../../types';
import { ApolloError } from '@apollo/client/errors';

/*-----------------TYPES-----------------*/
type TPayloadError = {
  error: ApolloError;
};

type TFetchUserData = {
  user: TUser | TApiUser;
};

type TFetchUsers = {
  users: TUser[] | TApiUser[];
};

export type TDeleteUser = {
  userId: string;
};

type TFetchUserRoles = {
  roles: TUserRole[];
};

export type TUpdateUserRoles = {
  organizationId: string;
  userId: string;
  roleIds: string[];
};

type TActionFlags = {
  createSuccess?: boolean;
  deleteSuccess?: boolean;
};

export interface IUser {
  authUser: TAuthUser | null;
  user: TUser | TApiUser | null;
  users: TUser[] | TApiUser[] | null;
  roles: TUserRole[] | null;
  actionFlags: {
    createSuccess: boolean;
    deleteSuccess: boolean;
  };
  error: ApolloError | null;
}

/*-----------------INITIAL STATE-----------------*/
export const initialState: IUser = {
  authUser: null,
  user: null,
  users: null,
  roles: null,
  actionFlags: {
    createSuccess: false,
    deleteSuccess: false,
  },
  error: null,
};

/*-----------------SLICE-----------------*/
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    /*-----------------Pending Actions/Reducers-----------------*/
    fetchUserDataPending: (state) => state,
    fetchUsersPending: (state) => state,
    fetchUserRolesPending: (state) => state,
    updateUserRolesPending: (
      state,
      _action: {
        payload: TUpdateUserRoles;
      },
    ) => state,
    createUserPending: (
      state,
      _action: {
        payload: TCreateUser;
      },
    ) => state,
    deleteUserPending: (
      state,
      _action: {
        payload: TDeleteUser;
      },
    ) => state,
    resetActionFlagsPending: (state, action: { payload: TActionFlags }) => {
      const { payload } = action;
      return {
        ...state,
        actionFlags: {
          ...state.actionFlags,
          ...payload,
        },
      };
    },

    /*-----------------Success Actions/Reducers-----------------*/
    fetchUserDataSuccess: (state, { payload }: { payload: TFetchUserData }) => {
      return {
        ...state,
        user: payload.user,
      };
    },
    fetchUsersSuccess: (state, { payload }: { payload: TFetchUsers }) => {
      return {
        ...state,
        users: payload.users,
      };
    },
    setAuthUser: (state, { payload }: { payload: TAuthUser }) => {
      return {
        ...state,
        authUser: payload,
      };
    },
    fetchUserRolesSuccess: (state, { payload }: { payload: TFetchUserRoles }) => {
      return {
        ...state,
        roles: payload.roles,
      };
    },
    createUserSuccess: (state) => {
      return {
        ...state,
        actionFlags: {
          ...state.actionFlags,
          createSuccess: true,
        },
      };
    },
    deleteUserSuccess: (state) => {
      return {
        ...state,
        actionFlags: {
          ...state.actionFlags,
          deleteSuccess: true,
        },
      };
    },

    /*-----------------Failed Actions/Reducers-----------------*/
    fetchUserDataFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    fetchUsersFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    fetchUserRolesFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    updateUserRolesFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    createUserFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    deleteUserFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
  },
});

export const {
  fetchUserDataPending,
  fetchUsersPending,
  updateUserRolesPending,
  deleteUserPending,
  fetchUserRolesPending,
  createUserPending,
  resetActionFlagsPending,
  fetchUserDataSuccess,
  fetchUsersSuccess,
  fetchUserRolesSuccess,
  createUserSuccess,
  deleteUserSuccess,
  setAuthUser,
  fetchUserDataFailed,
  fetchUsersFailed,
  deleteUserFailed,
  fetchUserRolesFailed,
  createUserFailed,
  updateUserRolesFailed,
} = userSlice.actions;

export default userSlice.reducer;
