import React, { useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Typography,
  Link,
  Select,
  OutlinedInput,
  MenuItem,
  SelectChangeEvent,
  FormControl,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';
import { getThemedBorder } from '../../../../../helpers';
import DeleteDialog from '../../../../../components/DeleteDialog';
import { useSelector, useDispatch } from '../../../../../hooks/redux';
import { selectActionFlags, selectUserRoles, selectUsers } from '../../../../../store/selectors/user';
import {
  createUserPending,
  deleteUserPending,
  resetActionFlagsPending,
  updateUserRolesPending,
} from '../../../../../store/slices/user';
import { TCreateUser, TUserRole, USER_ROLE } from '../../../../../types';
import InviteUser from './InviteUser';
import ExtraWidthButton from '../../../../../components/Buttons/ExtraWidthButton';
import CustomSnackbar from '../../../../../components/Snackbar';

/* ------- Styles ------- */
const StyledCell = styled(TableCell)(({ theme }) => ({
  padding: '12px',
  borderBottom: getThemedBorder(theme),
  borderTop: 'unset',
}));

const UserManagementHeader = styled('div')({
  display: 'flex',
});
const UserManagementTitle = styled(Typography)({
  flex: '1 1 100%',
  fontWeight: 700,
  fontSize: '22px',
  paddingBottom: '15px',
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/* ------- Types ------- */
interface IUserManagement {
  headerHeight: number;
}

const UserManagement: React.FC<IUserManagement> = ({ headerHeight }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const roles = useSelector(selectUserRoles);
  const { createSuccess, deleteSuccess } = useSelector(selectActionFlags);

  const userManagementHeaderRef = useRef<HTMLDivElement | null>(null);
  const scrollingSpaceHeight = headerHeight - (userManagementHeaderRef?.current?.clientHeight || 0) - 180;

  const [editedUser, setEditedUser] = useState<string | null>(null);
  const [userRoles, setUserRoles] = useState<string[]>([]);

  const handleUserEdit = (organizationId: string) => {
    dispatch(
      updateUserRolesPending({
        organizationId,
        userId: editedUser as string,
        roleIds: userRoles,
      }),
    );

    // clear states
    setUserRoles([]);
    setEditedUser(null);
  };

  const handleEditUser = (userId: string, roles: TUserRole[]) => {
    setEditedUser(userId);
    setUserRoles(roles.map((role) => role.roleId));
  };

  const handleChangeRoles = (event: SelectChangeEvent<typeof userRoles>) => {
    const {
      target: { value },
    } = event;
    setUserRoles(typeof value === 'string' ? value.split(',') : value);
  };
  const [openInviteUser, setOpenInviteUser] = useState<boolean>(false);
  const [deleteUser, setDeleteUser] = useState<{ userId: string; userName: string } | null>(null);

  // translates role ids to role names
  const getRenderedValue = (roleIds: string | string[]) => {
    if (Array.isArray(roleIds)) {
      return roleIds
        .map((roleId) =>
          t(`administration:roles:${roles?.find((role) => role.roleId === roleId)?.name.replace(/\s+/g, '')}`),
        )
        .join(', ');
    }

    return t(`administration:roles:${roles?.find((role) => role.roleId === roleIds)?.name.replace(/\s+/g, '')}`);
  };

  const handleSaveDeleteUser = (user) => {
    setDeleteUser({ userId: user.userId || user.id, userName: user.displayName || user.userName });
  };

  const handleDeleteUser = () => {
    dispatch(deleteUserPending({ userId: deleteUser?.userId as string }));
    setDeleteUser(null);
  };

  const handleInviteUser = (newUser: TCreateUser) => {
    dispatch(createUserPending(newUser));
  };

  const handleSnackbarClose = () => {
    if (createSuccess) {
      dispatch(resetActionFlagsPending({ createSuccess: false }));
    }
    if (deleteSuccess) {
      dispatch(resetActionFlagsPending({ deleteSuccess: false }));
    }
  };

  const mapAllowedRoles = (roles: TUserRole[]) =>
    Object.values(USER_ROLE)
      .filter((value) => value !== USER_ROLE.DEVICE_ADMINISTRATOR) // DeviceAdmin can't be assigned from UI
      .map((role) => ({
        ...roles?.find((r) => r.name === role),
        i18nKey: `administration:roles:${role}`,
      }));

  const tableHeader = [
    { id: 'space_1', label: '' },
    { id: 'name', label: t('administration:userManagement:headers:name'), width: '250px' },
    { id: 'email', label: t('administration:userManagement:headers:email'), width: '400px' },
    { id: 'role', label: t('administration:userManagement:headers:role') },
    { id: 'action_1', label: '', width: '180px' },
    { id: 'action_2', label: '', width: '180px' },
    { id: 'space_2', label: '' },
  ];

  const mapUsers = () =>
    users?.map((user) => (
      <TableRow key={user.userId || user.id}>
        <StyledCell sx={{ width: '0px', border: '0px' }} scope='row' align='left' />
        <StyledCell sx={{ width: '250px' }} scope='row' align='left'>
          {user.displayName || user.userName}
        </StyledCell>
        <StyledCell sx={{ width: '400px' }} align='left'>
          {user.emailAddress || user.email}
        </StyledCell>
        <StyledCell align='left'>
          {editedUser === user.userId || editedUser === user.id ? (
            <FormControl size='small'>
              <Select
                labelId='role-edit'
                value={userRoles}
                onChange={handleChangeRoles}
                input={<OutlinedInput />}
                renderValue={() => getRenderedValue(userRoles)}
                MenuProps={MenuProps}
                autoWidth
              >
                {roles &&
                  mapAllowedRoles(roles)?.map((role) => (
                    <MenuItem key={role.roleId} value={role.roleId}>
                      {getRenderedValue(role.roleId as string)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ) : (
            user.roles?.map((role) => getRenderedValue(role.roleId as string)).join(', ')
          )}
        </StyledCell>
        {!(editedUser === user.userId || editedUser === user.id) && (
          <>
            <StyledCell sx={{ width: '180px' }} align='left'>
              <Link
                component='button'
                onClick={() => handleEditUser(user.userId || user.id, user.roles)}
                variant='body1'
                sx={{ fontSize: '15px' }}
              >
                {t('administration:userManagement:actions:edit')}
              </Link>
            </StyledCell>
            <StyledCell sx={{ width: '180px' }} align='left'>
              <Link
                component='button'
                onClick={() => handleSaveDeleteUser(user)}
                variant='body1'
                sx={{ fontSize: '15px' }}
              >
                {t('administration:userManagement:actions:remove')}
              </Link>
            </StyledCell>
          </>
        )}
        {(editedUser === user.userId || editedUser === user.id) && (
          <>
            <StyledCell sx={{ width: '180px' }} align='left'>
              <Link component='button' onClick={() => setEditedUser(null)} sx={{ fontSize: '15px' }}>
                {t('common:cancel')}
              </Link>
            </StyledCell>
            <StyledCell sx={{ width: '180px' }} align='left'>
              <Link
                component='button'
                onClick={() => handleUserEdit(user.organizationId.organizationId)}
                sx={{ fontSize: '15px' }}
                disabled={!userRoles.length}
              >
                {t('common:save')}
              </Link>
            </StyledCell>
          </>
        )}
        <StyledCell sx={{ width: '0px', border: '0px' }} scope='row' align='left' />
      </TableRow>
    ));

  return (
    <>
      <UserManagementHeader ref={userManagementHeaderRef} data-testid={'user-management-header'}>
        <UserManagementTitle variant='body1'>{t('administration:userManagement:title')}</UserManagementTitle>
        <ExtraWidthButton variant='contained' onClick={() => setOpenInviteUser(true)}>
          {t('administration:userManagement:actions:invite')}
        </ExtraWidthButton>
      </UserManagementHeader>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 0,
          marginTop: '35px',
          maxHeight: scrollingSpaceHeight,
          boxShadow: 0,
        }}
        data-testid={'user-management-table'}
      >
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label='user table'>
          <TableHead sx={{ borderLeft: (theme) => getThemedBorder(theme) }}>
            <TableRow sx={{ boxShadow: '2px 4px 4px 0px rgba(0, 0, 0, 0.05)' }}>
              {tableHeader.map((item, index) => (
                <StyledCell
                  sx={{
                    borderLeft: (theme) => (index === 0 ? getThemedBorder(theme) : 0),
                    borderRight: (theme) => (index === tableHeader.length - 1 ? getThemedBorder(theme) : 0),
                    borderTop: (theme) => getThemedBorder(theme),
                  }}
                  key={item.id}
                  align='left'
                  style={{ ...(item.width && { width: item.width }) }}
                >
                  <b>{item.label || null}</b>
                </StyledCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{mapUsers()}</TableBody>
        </Table>
      </TableContainer>

      <DeleteDialog
        open={!!deleteUser}
        onConfirm={handleDeleteUser}
        onClose={() => setDeleteUser(null)}
        dialogTitle={t('administration:userManagement:deleteUser:title')}
        dialogText={
          <Trans
            i18nKey='administration:userManagement:deleteUser:text'
            values={{
              user: deleteUser?.userName,
              lineBreak: '<br />',
            }}
          />
        }
      />

      <InviteUser
        open={openInviteUser}
        onClose={() => setOpenInviteUser(false)}
        onInvite={handleInviteUser}
        // @ts-expect-error: need to align USER_ROLES enum with roles set from BE manually
        roles={mapAllowedRoles(roles)}
      />

      <CustomSnackbar
        severity='success'
        open={createSuccess || deleteSuccess}
        onClose={handleSnackbarClose}
        message={
          createSuccess
            ? t('administration:userManagement:invite:successMsg')
            : t('administration:userManagement:deleteUser:successMsg')
        }
      />
    </>
  );
};

export default UserManagement;
